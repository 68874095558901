import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';
import { cards, settings } from '../../utils/MockData/BlogMembers';
import { UseWowEffect } from '../AllComponents';
import '../../pages/Blog/Blog.css';

const BlogsReletedCards: React.FC = () => {
  UseWowEffect();

  return (
    <div className='blog wow fadeInUp' data-wow-delay='0.2s'>
      <Slider {...settings}>
        {cards.map((card, index) => (
          <div className='row m-auto  '>
            <div
              key={index}
              className='w-100'
              data-wow-delay={`0.1s`}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className='blog-item blog-item-custom '>
                <div className='blog-img'>
                  <img
                    src={card.img}
                    className='img-fluid w-100'
                    alt={`Blog ${index + 1}`}
                    loading='lazy'
                  />
                </div>
                <div className='blog-content text-dark border p-4'>
                  <p className='libre-baskerville-bold'>
                    <i className='fa fa-clock'></i> {card.date}
                  </p>
                  <h4 className='libre-baskerville-bold blog-title'>
                    {card.title}
                  </h4>
                  <p className='mb-4 nunito nunito-content'>
                    {card.description}
                  </p>
                  <NavLink
                    to='#'
                    className='btn btn-light rounded-pill py-2 px-4 nunito '
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default BlogsReletedCards;
