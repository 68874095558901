import concept from '../../assets/img/icon-concept.png';
import design from '../../assets/img/icon-design.png';
import development from '../../assets/img/icon-development.png';
import testing from '../../assets/img/icon-testing.png';
import deployment from '../../assets/img/icon-deployment.png';
import review from '../../assets/img/icon-review.png';

export const agileData = [
  {
    title: 'Concept',
    image: concept,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#fcf7ee',
  },
  {
    title: 'Design',
    image: design,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#fef5f6',
  },
  {
    title: 'Development',
    image: development,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#f0f5f4',
  },
  {
    title: 'Testing',
    image: testing,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#edf1fe',
  },
  {
    title: 'Deployment',
    image: deployment,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#fdf5fe',
  },
  {
    title: 'Maintenance',
    image: review,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#fefaf5',
  },
];
