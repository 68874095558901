import React, { useState } from 'react';
import { tabs } from '../../utils/MockData/ProductDetailAccordian';
import './ProducDetailAccordian.css';
import { UseWowEffect } from '../AllComponents';

const ProductDetailAccordian = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  UseWowEffect();
  return (
    <>
      <section className='full-width-section pt-8'>
        <div className='container tab-container'>
          <h1 className='section-header wow fadeInUp libre-baskerville-bold' data-wow-delay='0.3s'>
            Focus on cutting-edge technologies and cost-quality efficiency
          </h1>
          <div className='row'>
            <div className='col-lg-4 col-md-5 col-12 mb-4'>
              <div
                className='nav flex-column nav-pills'
                role='tablist'
                aria-orientation='vertical'
              >
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`nav-tab ${
                      activeTab === index ? 'active' : ''
                    } wow animate__zoomInRight nunito`}
                    data-wow-delay='0.3s' data-wow-duration="2.1s"
                    onClick={() => setActiveTab(index)}
                    role='tab'
                  >
                    {tab.title}
                    <p className='tab-description nunito'>
                      We design business models and growth strategies to be
                      effective and competitive.
                    </p>
                  </button>
                ))}
              </div>
            </div>
            <div className='col-lg-6 col-md-7  tab-content mt-2 p-0  '>
              <div
                className='tab-image-wrapper wow animate__fadeIn'
                data-wow-delay='0.1s'
              >
                {tabs.map((tab, index) => (
                  <img
                    key={index}
                    src={tab.image}
                    alt={tab.title}
                    width='100%'
                    height='100%'
                    className={`tab-image wow animate__zoomInLeft  ${
                      activeTab === index ? 'active' : ''
                    } ${
                      index < activeTab
                        ? 'slide-left'
                        : index > activeTab
                        ? 'slide-right'
                        : ''
                    }`}
                      data-wow-delay='0.3s' data-wow-duration="2.1s"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetailAccordian;
