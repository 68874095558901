import React from 'react';
import heroImage from '../../assets/img/hero-image.png';
import './HeroHeader.css';
import { UseWowEffect } from '../AllComponents';
import { NavLink } from 'react-router-dom';

const HeroHeader: React.FC = () => {
  UseWowEffect();
  return (
    <div className='hero-header overflow-hidden px-5'>
      <div className='rotate-img'>
        <div className='rotate-sty-2'></div>
      </div>
      <div className='row gy-5 align-items-center'>
      <div className='col-lg-6 wow  fadeInLeft' data-wow-delay='0.1s'>
          <h3
            className='display-5 text-dark mb-4 wow fadeInUp libre-baskerville-bold'
            data-wow-delay='0.3s'
          >
            TOP-RATED DEVELOPMENT COMPANY IN INDIA
          </h3>
          <p className='fs-4 mb-4 wow fadeInUp nunito' data-wow-delay='0.5s'>
            Meet the team you believe in and get the apps you want. We are here
            as your trusted software development company in India.
          </p>
          <NavLink to='/about-us'>
            <button className='button wow fadeInUp nunito'  data-wow-delay='0.7s'>
              LEARN MORE
            </button>
          </NavLink>
        </div>
        <div className='col-lg-6 w-1/2 wow animate__zoomIn' data-wow-delay='0.5s' >
          <img src={heroImage} className='img-fluid fade-in-out' alt='' loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;
