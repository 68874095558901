export const userAppFeatures = [
  ' Splash Screen',
  'Sign in Screen',
  'Register Now Screen',
  'Forgot Password Screen',
  'Verification Screen',
  'My Profile Screen',
];

export const orderManagement = [
  'Home Placeholder No Orders Screen',
  'Home Placeholder Screen',
  'Home Screen',
  'Select Pick Up Time Screen',
  'Select Delivery Time Screen',
  'My Orders Screen',
  'Orders Placed Screen',
];
export const locationAndAddressManagement = [
  'Add Address Screen',
  'Edit Address Screen',
  'Saved Addresses Screen',
];
export const communicationAndSupport = ['Contact Us Screen'];
export const settingsAndpreferences = [
  'Terms & Conditions Screen',
  'Settings Screen',
];

export const otherFeatures = ['Cart Screen'];

export const authenticationAndUserManagement = [
  'Splash Screen',
  'Sign in Screen',
  'Forgot Password Screen',
  'OTP Verification Screen',
  'Profile Screen',
];

export const deliveryOrderManagement = [
  'Pick Up Deliveries Screen',
  'Order Info Screen(All status in only one screen)',
];

export const adminAuthenticationAndUserManagement = ['Sign in Screen'];

export const adminOrderManagement = [
  'Home Screen',
  'Orders Screen',
  'Orders Info Screen',
  'Update Order Status Screen',
  'Orders Info Customer Screen',
];

export const serviceManagement = [
  'Service Management',
  'Super Admin Control',
  'Bulk Upload Services',
];

export const reportsAndAnalytics = [
  'Dashboard',
  'Reports (Daily, Order, Sales, Expense, Tax)',
  'Insight Screen',
];

export const adminOtherFeatures = [
  'Menu Screen',
  'User Management',
  'Automated Notifications (SMS)',
  'Printing and Invoicing Management',
  'Coupons, Discounts & Promo Codes Management',
];
