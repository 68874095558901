import React from 'react';
import './ProductsDetailsFeature.css';
import faqimages from '../../assets/img/faq-images.png';
import hireus from '../../assets/img/hire-us.png';
import {
  adminAuthenticationAndUserManagement,
  adminOrderManagement,
  adminOtherFeatures,
  authenticationAndUserManagement,
  communicationAndSupport,
  deliveryOrderManagement,
  locationAndAddressManagement,
  orderManagement,
  otherFeatures,
  reportsAndAnalytics,
  serviceManagement,
  settingsAndpreferences,
  userAppFeatures,
} from '../../utils/MockData/ProductsDetailsFeatures';
import { UseWowEffect } from '../AllComponents';
import { NavLink } from 'react-router-dom';

const otherSections = [
  {
    title: 'User App',
    content: [
      <p className='nunito'>
        Email Id:{' '}
        <NavLink to='info@mayora.com' className='nunito'>
          info@mayora.com
        </NavLink>
      </p>,
      <p className='nunito'>
        Password: <strong>123456</strong>
      </p>,
      <p className='nunito'>
        Download Apk:{' '}
        <NavLink to='#' className='nunito' rel='nofollow'>
          Click Here
        </NavLink>
      </p>,
    ],
  },
  {
    title: 'What will you get?',
    content: [
      'Full Dart source code for both iOS and Android',
      'Backend admin panel code',
      'Figma File',
      'Well documentation help using the product effectively',
      'One-time purchase, Lifetime use and free version upgrade',
    ],
    isList: true,
  },
  {
    title:
      'Please note that the live preview images included are for preview purposes only. The bundle does not contain the actual images and videos. These are solely for demonstration purposes.',
    content: (
      <div className='mb-3 col-lg-6 col-md-7 col-12'>
        <img src={faqimages} className='img-fluid wow animate__zoomIn' data-wow-delay='0.1s' loading='lazy' />
      </div>
    ),
  },
  {
    title: 'Support',
    content: [
      <p className='nunito'>
        We offer support to our customers{' '}
        <strong>Mon-Sat 9:00am-6:00 pm (GMT +5:30)</strong> – we are located in
        India. We reply to all questions within 2 working days.
      </p>,
      <p className='nunito'>
        Send your issues or queries to our support email:{' '}
        <NavLink to='mailto:mayora@gmail.com' className='nunito'>
          mayora@gmail.com
        </NavLink>
      </p>,
    ],
  },
  {
    title:
      'Concerned about the success of your project? Hire Our IT Professionals',
    content: (
      <img
        src={hireus}
        alt='Hire IT Professionals'
        className='img-fluid mb-3 wow animate__zoomIn' data-wow-delay='0.1s'
        loading='lazy'
      />
    ),
  },
  {
    title: 'Refund Policy',
    content: [
      'We don’t offer a refund. (If the item is downloaded)',
      'Please read the description and compatibility content thoroughly before purchasing as we don’t offer a refund if you buy it by mistake.',
    ],
    isList: true,
  },
];

const ProductsDetailsFeatures = () => {
  UseWowEffect();
  return (
    <div className='container py-5'>
      <section className='mb-4'>
        <h3
          id='item-description__user-app-features'
          className='mb-5 text-primary bottom wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          User App Screens
        </h3>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Authentication and User Management:
        </h5>
        <ul className='group '>
          {userAppFeatures.map((feature, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {feature}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Order Management:
        </h5>
        <ul className='group '>
          {orderManagement.map((managment, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {managment}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Location and Address Management:
        </h5>
        <ul className='group '>
          {locationAndAddressManagement.map((locationandaddress, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {locationandaddress}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Communication and Support:
        </h5>
        <ul className='group '>
          {communicationAndSupport.map((comminutionsupport, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {comminutionsupport}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Settings and Preferences:
        </h5>
        <ul className='group '>
          {settingsAndpreferences.map((settings, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {settings}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__user-app-features'
          className='mb-3 wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Other Features:
        </h5>
        <ul className='group '>
          {otherFeatures.map((otherfeatures, index) => (
            <li
              key={index}
              className='group-item  wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {otherfeatures}
            </li>
          ))}
        </ul>
      </section>

      <section className='mb-4'>
        <h3
          id='item-description__admin-features'
          className='mb-5 text-primary bottom wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Delivery App Screens
        </h3>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Authentication and User Management:
        </h5>
        <ul className='group'>
          {authenticationAndUserManagement.map((auth, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {auth}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Order Management:
        </h5>
        <ul className='group'>
          {deliveryOrderManagement.map((deliverymanagmet, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {deliverymanagmet}
            </li>
          ))}
        </ul>
      </section>

      <section className='mb-4'>
        <h3
          id='item-description__admin-features'
          className='mb-5 text-primary bottom wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Admin Panel Screens
        </h3>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Authentication and User Management:
        </h5>
        <ul className='group'>
          {adminAuthenticationAndUserManagement.map((adminfeature, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {adminfeature}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Order Management:
        </h5>
        <ul className='group'>
          {adminOrderManagement.map((adminordermanagement, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {adminordermanagement}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Service Management:
        </h5>
        <ul className='group'>
          {serviceManagement.map((servicemanagemant, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {servicemanagemant}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Reports and Analytics:
        </h5>
        <ul className='group'>
          {reportsAndAnalytics.map((reportsandanalytics, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {reportsandanalytics}
            </li>
          ))}
        </ul>
        <h5
          id='item-description__admin-features'
          className='mb-3  wow fadeInUp libre-baskerville-bold'
          data-wow-delay='0.1s'
        >
          Other Features:
        </h5>
        <ul className='group'>
          {adminOtherFeatures.map((adminotherfeatures, index) => (
            <li
              key={index}
              className='group-item wow fadeInUp nunito'
              data-wow-delay={`${0.1 + index * 0.1}s`}
            >
              {adminotherfeatures}
            </li>
          ))}
        </ul>
      </section>
      {otherSections.map((section, index) => (
        <section className='mb-4' key={index}>
          <h3
            className={`mb-3 text-primary bottom wow fadeInUp libre-baskerville-bold`}
            data-wow-delay={`${0.1 + index * 0.1}s`}
          >
            {section.title}
          </h3>
          {section.isList ? (
            <ul className='group'>
              {section.content.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  className='group-item wow fadeInUp nunito'
                  data-wow-delay={`${0.1 + index * 0.1 + itemIndex * 0.1}s`}
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            section.content
          )}
        </section>
      ))}
    </div>
  );
};
export default ProductsDetailsFeatures;
