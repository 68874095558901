import React, { useState } from 'react';
import './JobApplicationForm.css';
import InputField from '../InputFeild/InputFeild';
import { CareerDetails } from '../../utils/APIsService/APIsServices';
import type {
  FormData,
  JobApplicationFormProps,
} from '../../types/JobApplicationForm';
import toast, { Toaster } from 'react-hot-toast';
import emailjs from 'emailjs-com';

const JobApplicationForm: React.FC<JobApplicationFormProps> = ({
  onClose,
  formData,
}) => {
  const [applicationData, setApplicationData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    position: formData.position || '',
    experience: '',
    coverLetter: '',
    file: null,
  });

  const [loading, setLoading] = useState(false);
  const JobapplytempleteId = process.env.REACT_APP_JOB_APPLY_TEMPLETE_ID;
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const userId = process.env.REACT_APP_USER_ID;

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === 'file') {
      const input = e.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const selectedFile = input.files[0];
        setApplicationData((prevData) => ({ ...prevData, file: selectedFile }));
      }
    } else {
      setApplicationData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const applicationDataForm = new FormData();
    applicationDataForm.append('name', applicationData.name);
    applicationDataForm.append('email', applicationData.email);
    applicationDataForm.append('phone', applicationData.phone);
    applicationDataForm.append('position', applicationData.position);
    applicationDataForm.append('cover', applicationData.coverLetter);
    if (applicationData.file) {
      applicationDataForm.append('file', applicationData.file);
    }

    let apiSuccess = false;

    try {
      const response = await CareerDetails.submitApplication(
        applicationDataForm
      );
      const successMessage =
        response.message || 'Application submitted successfully!';
      toast.success(successMessage);
      apiSuccess = true;
    } catch (error: any) {
      console.error('Error submitting application:', error);
    }
    const emailParams = {
      name: applicationData.name,
      email: applicationData.email,
      phone: applicationData.phone,
      position: applicationData.position,
      coverLetter: applicationData.coverLetter,
    };
    if (!apiSuccess) {
      setTimeout(async () => {
        try {
          await emailjs.send(
            `${serviceId}`,
            `${JobapplytempleteId}`,
            emailParams,
            `${userId}`
          );
          toast.success('Email sent successfully!');
        } catch (error: any) {
          console.error('Error sending email:', error);
          toast.error('There was an error sending the email.');
        } finally {
          setLoading(false);
          setApplicationData({
            name: '',
            email: '',
            phone: '',
            position: formData.position || '',
            experience: '',
            coverLetter: '',
            file: null,
          });
        }
        onClose();
      }, 1000);
    }
  };

  return (
    <div className='popup-overlay'>
      <div className='popup-content'>
        <button className='close-button' onClick={onClose}>
          X
        </button>
        <h2 className='libre-baskerville-bold'>Apply for Job</h2>
        <form className='p-4' onSubmit={handleSubmit}>
          <InputField
            label='Position'
            placeholder={applicationData.position}
            className='libre-baskerville-bold form-control'
            readOnly
          />
          <div className='name-group'>
            <InputField
              label='Name'
              type='text'
              name='name'
              placeholder='Enter your name'
              value={applicationData.name}
              onChange={handleChange}
              className='form-control nunito'
              required
            />
            <InputField
              label='Phone'
              type='text'
              name='phone'
              placeholder='Enter your phone number'
              value={applicationData.phone}
              onChange={handleChange}
              className='form-control nunito'
              required
            />
            <InputField
              label='Email'
              type='email'
              name='email'
              placeholder='Enter your email address'
              value={applicationData.email}
              onChange={handleChange}
              className='form-control nunito'
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='coverLetter'>Cover Letter</label>
            <textarea
              id='coverLetter'
              name='coverLetter'
              placeholder='Enter your cover letter'
              value={applicationData.coverLetter}
              onChange={handleChange}
              style={{ height: '80px' }}
              className='form-control nunito'
              required
            ></textarea>
          </div>
          <button type='submit' className='btn icon con-border-remove btn-shiny nunito' disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Application'}
          </button>
        </form>
        <div className='toaster-div'>
          <Toaster position='top-center' reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
