import  { lazy } from 'react';

export const RiAngularjsLine = lazy(() =>
  import('react-icons/ri').then((module) => ({
    default: module.RiAngularjsLine,
  }))
);
export const FaReact = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaReact }))
);
export const FaVuejs = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaVuejs }))
);
export const IoLogoJavascript = lazy(() =>
  import('react-icons/io').then((module) => ({
    default: module.IoLogoJavascript,
  }))
);
export const SiTypescript = lazy(() =>
  import('react-icons/si').then((module) => ({ default: module.SiTypescript }))
);
export const DiHtml5 = lazy(() =>
  import('react-icons/di').then((module) => ({ default: module.DiHtml5 }))
);
export const FaCss3Alt = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaCss3Alt }))
);
export const RiTailwindCssFill = lazy(() =>
  import('react-icons/ri').then((module) => ({
    default: module.RiTailwindCssFill,
  }))
);
export const SiFlutter = lazy(() =>
  import('react-icons/si').then((module) => ({ default: module.SiFlutter }))
);
export const FaApple = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaApple }))
);
export const IoLogoAndroid = lazy(() =>
  import('react-icons/io').then((module) => ({ default: module.IoLogoAndroid }))
);
export const FaSwift = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaSwift }))
);
export const IoLogoIonic = lazy(() =>
  import('react-icons/io').then((module) => ({ default: module.IoLogoIonic }))
);
export const TbBrandReactNative = lazy(() =>
  import('react-icons/tb').then((module) => ({
    default: module.TbBrandReactNative,
  }))
);
export const SiXamarin = lazy(() =>
  import('react-icons/si').then((module) => ({ default: module.SiXamarin }))
);
export const AiOutlineDotNet = lazy(() =>
  import('react-icons/ai').then((module) => ({
    default: module.AiOutlineDotNet,
  }))
);
export const FaPhp = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaPhp }))
);
export const FaJava = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaJava }))
);
export const FaNodeJs = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaNodeJs }))
);
export const FaPython = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaPython }))
);
export const FaLaravel = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaLaravel }))
);
export const DiCodeigniter = lazy(() =>
  import('react-icons/di').then((module) => ({ default: module.DiCodeigniter }))
);
export const SiDjango = lazy(() =>
  import('react-icons/si').then((module) => ({ default: module.SiDjango }))
);
export const SiCakephp = lazy(() =>
  import('react-icons/si').then((module) => ({ default: module.SiCakephp }))
);
export const BiSolidTShirt = lazy(() =>
  import('react-icons/bi').then((module) => ({ default: module.BiSolidTShirt }))
);
export const FaCartShopping = lazy(() =>
  import('react-icons/fa6').then((module) => ({
    default: module.FaCartShopping,
  }))
);
export const FaShippingFast = lazy(() =>
  import('react-icons/fa').then((module) => ({
    default: module.FaShippingFast,
  }))
);
export const FaLaptopHouse = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaLaptopHouse }))
);
export const FaRegHospital = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaRegHospital }))
);
export const MdCastForEducation = lazy(() =>
  import('react-icons/md').then((module) => ({
    default: module.MdCastForEducation,
  }))
);
export const BiSolidBank = lazy(() =>
  import('react-icons/bi').then((module) => ({ default: module.BiSolidBank }))
);
export const DiHtml5Multimedia = lazy(() =>
  import('react-icons/di').then((module) => ({
    default: module.DiHtml5Multimedia,
  }))
);
export const FaBuilding = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaBuilding }))
);
export const PiWatchFill = lazy(() =>
  import('react-icons/pi').then((module) => ({ default: module.PiWatchFill }))
);
export const FaMedkit = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaMedkit }))
);
export const FaPlane = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaPlane }))
);
