import React from 'react';
import { InputFieldProps } from '../../types/InputFieldTypes';

const InputField: React.FC<InputFieldProps> = ({
  label,
  type = 'text',
  name,
  placeholder,
  value,
  onChange,
  required,
  readOnly,
  className
  
}) => (
  <div className='form-group'>
    <label htmlFor={name} className='nunito'>{label}</label>
    <input
      type={type}
      className={className}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    />
  </div>
);

export default InputField;
