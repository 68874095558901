import {
  BlogsApiResponse,
  CareersApiResponse,
  CareersDetailsApiResponse,
  ContactApplicationApiResponse,
  JobApplicationApiResponse,
} from '../../types/APIs';
import ApiHandler from '../apis/Api';

export const CareerService = {
  getCareers: async (): Promise<CareersApiResponse> => {
    return await ApiHandler.get<CareersApiResponse>('careers');
  },
};

export const BlogsService = {
  getBlogs: async (): Promise<BlogsApiResponse> => {
    return await ApiHandler.get<BlogsApiResponse>('blogs');
  },
};

export const CareerDetails = {
  getCareersDetails: async (id: number): Promise<CareersDetailsApiResponse> => {
    return await ApiHandler.get<CareersDetailsApiResponse>(`careers/${id}`);
  },

  submitApplication: async (
    formData: FormData
  ): Promise<JobApplicationApiResponse> => {
    return await ApiHandler.post<JobApplicationApiResponse, FormData>(
      'apply-job',
      formData
    );
  },
};

export const ContactDetails = {
  sendMessageApplication: async (
    formData: FormData
  ): Promise<ContactApplicationApiResponse> => {
    return await ApiHandler.post<ContactApplicationApiResponse, FormData>(
      'contact-us',
    formData
    );
  },
};
