import React from 'react';
import './FeatureSection.css';
import { FeatureSectionProps } from '../../types/FeatureSectionTypes';
import { UseWowEffect } from '../AllComponents';

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  content,
  features,
  imageSrc,
  imageOrder,
  contentOrder,
  animationClass,
}) => {
  UseWowEffect();
  return (
    <>
      <section className='about-area ptb-100'>
        <div className='container'>
          <div className='row align-items-center'>
            <div
              className={`col-lg-6 col-md-12 wow ${animationClass} order-lg-${imageOrder}`}
              data-wow-delay='0.3s'
            >
              <div className='about-image'>
                <img src={imageSrc} alt={title} className='custom-img' loading="lazy" />
              </div>
            </div>
            <div className={`col-lg-6 col-md-12 order-lg-${contentOrder}`}>
              <div
                className={`about-content align-items-center text-left wow ${animationClass}`}
                data-wow-delay='0.3s'
              >
                <span className='sub-title nunito'>Our Features</span>
                <h2 className='libre-baskerville-bold'>{title}</h2>
                <p className='nunito'>{content}</p>
                <ul className='features-list'>
                  {features.map((feature, index) => (
                    <li
                      key={index}
                      className='wow fadeInUp nunito'
                      data-wow-delay={`${0.1 * index}s`}
                    >
                      <span>
                        <i className='fas fa-check'></i> {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureSection;
