import { useEffect } from 'react';
import { MetaTagProps } from '../../types/MetaTagTypes';

const useMetaTags = ({
  title,
  description,
  keywords,
  image,
  url,
  author,
  language,
  ogtitle,
  ogImage,
  ogdescription,
  ogtype,
  ogurl,
  canonical,
  ...rest
}: MetaTagProps) => {
  useEffect(() => {
    document.title = title;

    const setMetaTag = (name: string, content: string) => {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('name', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    };

    const setPropertyTag = (property: string, content: string) => {
      let element = document.querySelector(`meta[property="${property}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('property', property);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    };

    setMetaTag('description', description);
    if (keywords) setMetaTag('keywords', keywords);
    if (author) setMetaTag('author', author);
    if (language) setMetaTag('language', language);
    if (canonical) {
      let link = document.querySelector(`link[rel="canonical"]`);
      if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
      }
      link.setAttribute('href', canonical);
    }

    if (ogtitle) setPropertyTag('og:title', ogtitle);
    if (ogdescription) setPropertyTag('og:description', ogdescription);
    if (ogImage) setPropertyTag('og:image', ogImage);
    if (ogtype) setPropertyTag('og:type', ogtype);
    if (ogurl) setPropertyTag('og:url', ogurl);
    if (image) setPropertyTag('og:image', image);
    if (url) setPropertyTag('og:url', url);

    setMetaTag('viewport', 'width=device-width, initial-scale=1.0');
    setMetaTag('X-UA-Compatible', 'ie=edge');

    let charsetTag = document.querySelector(`meta[charset]`);
    if (!charsetTag) {
      charsetTag = document.createElement('meta');
      charsetTag.setAttribute('charset', 'UTF-8');
      document.head.appendChild(charsetTag);
    }
    return () => {
      document.title = 'Mayora Infotech';
    };
  }, [
    title,
    description,
    keywords,
    image,
    url,
    author,
    language,
    ogtitle,
    ogdescription,
    ogImage,
    ogtype,
    ogurl,
    canonical,
    rest,
  ]);
};

export default useMetaTags;
