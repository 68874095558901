import React from 'react';
import { TechCard } from '../AllComponents';
import { frameworks } from '../../assets/Icons/Icon';

const Frameworks = () => (
  <div className='tech-grid'>
    {frameworks.map((tech, index) => (
      <TechCard key={index} icon={tech.icon} name={tech.name} />
    ))}
  </div>
);

export default Frameworks;
