import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import { ScrollToTop, Spinner } from './components/AllComponents';
import './utils/GlobalStyles/Animate.min.css';
import './utils/GlobalStyles/Animate.css';
import {
  Layout,
  Home,
  About,
  Service,
  OurFeatures,
  Blog,
  BlogSingle,
  TestimonialSection,
  Products,
  ProductsDetails,
  Contact,
  Careers,
  NoPage,
  JobDetails,
  SiteMap,
} from './pages/index';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

export const App: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <ScrollToTop />
        <Toaster />
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='about-us' element={<About />} />
            <Route path='services' element={<Service />} />
            <Route path='features' element={<OurFeatures />} />
            <Route path='blog/*' element={<Blog linkTo={`blog-details`} />} />
            <Route path='blog/blog-details' element={<BlogSingle />} />
            <Route path='testimonialsection' element={<TestimonialSection />} />
            <Route path='products/*' element={<Products />} />
            <Route
              path='products/products-details'
              element={<ProductsDetails />}
            />
            <Route path='contact-us' element={<Contact />} />
            <Route path='career/*' element={<Careers />} />
            <Route path='career/job-details/:id' element={<JobDetails />} />
            <Route path='sitemap' element={<SiteMap />} />
          </Route>
          <Route path='*' element={<NoPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
