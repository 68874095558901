import React from 'react';
import './BackToTop.css';

const BackToTop: React.FC = () => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <span
        onClick={handleBackToTop}
        className='btn btn-primary btn-lg-square back-to-top'
      >
        <i className='fa fa-arrow-up'></i>
      </span>
    </div>
  );
};

export default BackToTop;
