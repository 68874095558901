import React, { useState } from 'react';
import Aboutimage from '../../assets/img/about.png';
import './Faq.css';
import { UseWowEffect } from '../AllComponents';

const FAQ: React.FC = () => {
  UseWowEffect();
  const [isOpenOne, setIsOpenOne] = useState(true);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);

  const handleClickOne = () => {
    setIsOpenOne(!isOpenOne);
    setIsOpenTwo(false);
    setIsOpenThree(false);
  };

  const handleClickTwo = () => {
    setIsOpenTwo(!isOpenTwo);
    setIsOpenOne(false);
    setIsOpenThree(false);
  };

  const handleClickThree = () => {
    setIsOpenThree(!isOpenThree);
    setIsOpenOne(false);
    setIsOpenTwo(false);
  };

  return (
    <>
      <div className='container-fluid FAQ bg-light overflow-hidden py-5'>
        <div className='container py-5'>
          <div className='row g-5 align-items-center'>
            <div className='col-lg-6 wow animate__pulse' data-wow-delay='0.1s'>
              <div className='accordion' id='accordionExample'>
                <div className='accordion-item border-0 mb-4'>
                  <h2
                    className='accordion-header libre-baskerville-bold'
                    id='headingOne'
                  >
                    <button
                      className={`accordion-button rounded-top ${
                        isOpenOne ? '' : 'collapsed'
                      }`}
                      onClick={handleClickOne}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Why Choose Mayora Infotech's Email Services?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className={`accordion-collapse collapse ${
                      isOpenOne ? 'show' : ''
                    }`}
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body my-2'>
                      <p className='nunito'>
                        "At Mayora Infotech, we provide email solutions that
                        prioritize security, scalability, and seamless
                        integration tailored to your business needs. Our
                        services are designed with advanced encryption and data
                        protection, ensuring your information is always safe.
                        With high reliability, responsive customer support, and
                        cost-effective packages, Mayora Infotech is dedicated to
                        enhancing your communication efficiency and safeguarding
                        your data. Choose us for a trusted partner in powering
                        your business email needs."
                      </p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border-0 mb-4'>
                  <h2
                    className='accordion-header libre-baskerville-bold'
                    id='headingTwo'
                  >
                    <button
                      className={`accordion-button rounded-top ${
                        isOpenTwo ? '' : 'collapsed'
                      }`}
                      onClick={handleClickTwo}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Are there any hidden charges?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className={`accordion-collapse collapse ${
                      isOpenTwo ? 'show' : ''
                    }`}
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body my-2'>
                      <p className='nunito'>
                        To ensure transparency, it's important to inquire about
                        any potential hidden charges associated with Mayora
                        Infotech's email services. While their website
                        emphasizes cost-effective solutions, specific details
                        about pricing and additional fees aren't readily
                        available. To obtain a comprehensive understanding of
                        all potential costs, including setup fees, maintenance
                        charges, or any other hidden expenses, it's advisable to
                        contact their sales or customer support team directly.
                        This will help you make an informed decision and avoid
                        any unexpected charges in the future.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border-0'>
                  <h2
                    className='accordion-header libre-baskerville-bold'
                    id='headingThree'
                  >
                    <button
                      className={`accordion-button rounded-top ${
                        isOpenThree ? '' : 'collapsed'
                      }`}
                      onClick={handleClickThree}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      What are the key challenges of email marketing?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className={`accordion-collapse collapse ${
                      isOpenThree ? 'show' : ''
                    }`}
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body my-2'>
                      <h5 className='libre-baskerville-bold'>
                        Email marketing is a powerful tool for businesses, but
                        it comes with several challenges. Here are some of the
                        key hurdles:
                      </h5>
                      <h6 className='libre-baskerville-bold'>
                        1. Deliverability Issues
                      </h6>
                      <p className='nunito'>
                        <li>
                          Emails can be flagged as spam or blocked by filters,
                          which affects their ability to reach recipients’
                          inboxes. Maintaining a good sender reputation and
                          keeping a clean email list is essential.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        2. Audience Segmentation
                      </h6>
                      <p className='nunito'>
                        <li>
                          Sending generic emails can lead to lower engagement.
                          Effective segmentation requires understanding customer
                          data and behavior to send targeted, personalized
                          content that resonates with each audience group.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        3. Personalization and Relevance
                      </h6>
                      <p className='nunito'>
                        <li>
                          Personalized emails are more effective, but crafting
                          relevant content for each user segment can be
                          time-consuming and resource-intensive. It also
                          requires a robust data infrastructure.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        4. Maintaining Engagement and Avoiding Unsubscribes
                      </h6>
                      <p className='nunito'>
                        <li>
                          Engaging recipients without overwhelming them is a
                          fine balance. Sending too many emails or content that
                          doesn’t add value can lead to unsubscribes and lower
                          engagement rates.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        5. Content Creation
                      </h6>
                      <p className='nunito'>
                        <li>
                          Consistently creating fresh, valuable content that
                          meets subscribers’ needs and aligns with brand goals
                          can be challenging. Without quality content, campaigns
                          may lack impact and effectiveness.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        6. Adapting to Mobile Optimization
                      </h6>
                      <p className='nunito'>
                        <li>
                          With the majority of users reading emails on mobile
                          devices, ensuring emails are mobile-friendly is
                          crucial. Poorly formatted emails on mobile can lead to
                          lower engagement and unsubscribes.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        7. Data Privacy and Compliance
                      </h6>
                      <p className='nunito'>
                        <li>
                          Regulations like GDPR and CAN-SPAM require compliance
                          with strict data privacy standards, including managing
                          consent and data security. Non-compliance can result
                          in hefty fines and damage to brand reputation.
                        </li>
                      </p>
                      <h6 className='libre-baskerville-bold'>
                        8. Measuring ROI
                      </h6>
                      <p className='nunito'>
                        <li>
                          Tracking and analyzing email marketing effectiveness
                          can be challenging due to various factors influencing
                          open rates, click-through rates, and conversions.
                          Calculating a precise return on investment requires
                          robust analytics.
                        </li>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 wow animate__zoomIn' data-wow-delay='0.1s'>
              <div className='FAQ-img RotateMoveRight rounded'>
                <img
                  src={Aboutimage}
                  className='img-fluid w-100'
                  alt='About Us'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
