import React from 'react';
import { NavLink } from 'react-router-dom';
import './CopyRight.css';

const CopyRight = () => {
  return (
    <div>
      <div className='container-fluid copyright py-4'>
        <div className='container'>
          <div className='row g-4 align-items-center'>
            <div className='col-md-6 text-center text-md-start'>
              <span className='text-white'>
                <NavLink className='text-mayora libre-baskerville-bold' to='/'>
                  <i className='fas fa-copyright text-light me-2'></i>
                  Mayora Infotech, All rights reserved.
                </NavLink>
              </span>
            </div>
            <div className='col-md-6 text-center  text-white footer-text libre-baskerville-bold'>
              Designed By{' '}
              <NavLink className='text-mayora' to='/'>
                Mayora Infotech
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyRight;
