import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const token = process.env.REACT_APP_TOKEN;

const ApiHandler = {
  get: async <T>(endpoint: string): Promise<T> => {
    const response = await axios.get<T>(`${API_BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  },

  post: async <T, U>(endpoint: string, data: U): Promise<T> => {
    const response = await axios.post<T>(`${API_BASE_URL}${endpoint}`, data, {
      headers: {
        Authorization: `${token}`,
        ...(data instanceof FormData
          ? {}
          : { 'Content-Type': 'application/json' }),
      },
    });
    return response.data;
  },
};

export default ApiHandler;
