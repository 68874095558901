import webDevelopmentService from '../../assets/img/web-development-service.png';
import mobileAppDevelopment from '../../assets/img/mobile-app-development.png';
import iotDevelopment from '../../assets/img/iot-development.png';
import hireDevelopers from '../../assets/img/hire-developers.png';
import devopsService from '../../assets/img/devops-service.png';
import uiuxImage from '../../assets/img/uiux-icon.png';

export const serviceData = [
  {
    image: webDevelopmentService,
    title: 'Web Development',
    color: '#fcf7ee',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
  {
    image: mobileAppDevelopment,
    title: 'Mobile App Development',
    color: '#edf1fe',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
  {
    image: iotDevelopment,
    title: 'IOT Development',
    color: '#f0f5f4',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
    animation: 'fadeInUp',
  },
  {
    image: hireDevelopers,
    title: 'WebRTC',
    color: '#fef5f6',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
  {
    image: devopsService,
    title: 'DevOps',
    color: '#fdf5fe',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
  {
    image: uiuxImage,
    title: 'UI/UX Design',
    color: '#fefaf5',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
];
