import uncategorized from '../../assets/img/uncategorized-blog.jpg';
import webdevelopment from '../../assets/img/web-development-blog.jpg';
import development from '../../assets/img/development-blog.png';
import mobileappdevelopment from '../../assets/img/mobile-app-devlopment-blog.jpg';

export const cards = [
  {
    img: mobileappdevelopment,
    title:
      'Top 8 React Native Component Libraries for Your Next App Development',
    description: 'Mobile app development has emerged as an essential',
    date: 'Jun 05, 2023',
  },
  {
    img: uncategorized,
    title: 'Native App vs Hybrid App Development',
    description: 'Gone are the days when you could rely solely on billboards',
    date: 'Apr 12, 2022',
  },
  {
    img: webdevelopment,
    title:
      'PHP Vs. ASP.NET: How to Choose the Perfect One for Your Next Project',
    description: 'PHP Vs. ASP.NET: How to Choose the Perfect One for Your Next',
    date: 'Jan 24, 2022',
  },
  {
    img: development,
    title: 'Top 10 Web and Mobile App Ideas to Nurture Your Business Faster',
    description: 'Why Mobile App Development is Essential for Businesses',
    date: 'Apr 20, 2021',
  },
];
export const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
