export const footerLinks = [
  {
    title: 'Services',
    links: [
      { path: '/services', label: 'Mobile App Development' },
      { path: '/services', label: 'Back End Development' },
      { path: '/services', label: 'Website Development' },
      { path: '/services', label: 'Emerging Technologies' },
      { path: '/services', label: 'Digital Marketing' },
    ],
  },
  {
    title: 'Products',
    links: [
      { path: '/products', label: 'Laundry App' },
      { path: '/products', label: 'Screen Moniter App' },
      { path: '/products', label: 'Dating App' },
    ],
  },
  {
    title: 'Technology',
    links: [
      { path: '/services', label: 'ReactJS' },
      { path: '/services', label: 'AngularJS' },
      { path: '/services', label: 'NodeJS' },
      { path: '/services', label: 'React Native' },
      { path: '/services', label: 'IOS' },
      { path: '/services', label: 'Android' },
    ],
  },
  {
    title: 'Hire Developer',
    links: [
      { path: '/career', label: 'ReactJS Developer' },
      { path: '/career', label: 'AngularJS Developer' },
      { path: '/career', label: 'NodeJS Developer' },
      { path: '/career', label: 'React Native Developer' },
      { path: '/career', label: 'IOS Developer' },
      { path: '/career', label: 'Android Developer' },
    ],
  },
];
