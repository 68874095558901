import React, { useState, useEffect } from 'react';
import './TechnologySection.css';
import {
  BackendTech,
  FrontendTech,
  MobileTech,
  UseWowEffect,
} from '../AllComponents';
import Frameworks from '../Framework/Frameworks';

const TechnologySection = () => {
  UseWowEffect();
  const [activeTab, setActiveTab] = useState('mobile');
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className='technology-section'>
      <h2 className='mb-5 text-primary libre-baskerville-bold' data-wow-delay='0.1s'>
        Tech-Stack We Use in Software Development
      </h2>
      <section className='tech-navbar' data-wow-delay='0.3s'>
        <ul className='nunito'>
          <li
            className={activeTab === 'mobile' ? 'active' : ''}
            onClick={() => handleTabClick('mobile')}
          >
            Mobile
          </li>
          <li
            className={activeTab === 'front-end' ? 'active' : ''}
            onClick={() => handleTabClick('front-end')}
          >
            Front-end
          </li>
          <li
            className={activeTab === 'back-end' ? 'active' : ''}
            onClick={() => handleTabClick('back-end')}
          >
            Back-end
          </li>
          <li
            className={activeTab === 'frame-work' ? 'active' : ''}
            onClick={() => handleTabClick('frame-work')}
          >
            Frameworks
          </li>
        </ul>
      </section>
      <div className='tech-content'>
        {activeTab === 'mobile' && <MobileTech />}
        {activeTab === 'front-end' && <FrontendTech />}
        {activeTab === 'back-end' && <BackendTech />}
        {activeTab === 'frame-work' && <Frameworks />}
      </div>
    </div>
  );
};

export default TechnologySection;
