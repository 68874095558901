import { useState, useEffect } from 'react';

const useFetch = <T>(apiCall: () => Promise<T>, shouldFetch: boolean) => {
  const [data, setData] = useState<T | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!shouldFetch) return;
      setLoading(true);
      try {
        setLoading(true);
        const result = await apiCall();
        setData(result);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [shouldFetch]);

  return { data, loading, error };
};

export default useFetch;
