import React from 'react';
import './TestimonialCard.css';
import { TestimonialCardProps } from '../../types/TestimonialTypes';

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imgSrc,
  name,
  location,
  message,
  stars,
}) => {
  const starElements = Array.from({ length: stars }, (_, i) => (
    <i key={i} className='fas fa-star text-primary'></i>
  ));

  return (
    <div className='testimonial-item pb-5' >
      <div className='testimonial-inner text-center  p-5'>
        <div className='d-flex align-items-center justify-content-center mb-4 '>
          <div className='testimonial-inner-img  me-4'>
            <img src={imgSrc} className='img-fluid rounded-circle' alt={name} loading="lazy" />
          </div>
          <div>
            <h5 className='mb-2 libre-baskerville-bold'>{name}</h5>
            <p className='mb-0 nunito'>{location}</p>
          </div>
        </div>
        <p className='fs-7 mb-3 nunito'>{message}</p>
        <div className='text-center'>
          <div className='d-flex justify-content-center '>{starElements}</div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
