import React, { Suspense } from 'react';
import {
  AiOutlineDotNet,
  BiSolidBank,
  BiSolidTShirt,
  DiCodeigniter,
  DiHtml5,
  DiHtml5Multimedia,
  FaApple,
  FaBuilding,
  FaCartShopping,
  FaCss3Alt,
  FaJava,
  FaLaptopHouse,
  FaLaravel,
  FaMedkit,
  FaNodeJs,
  FaPhp,
  FaPlane,
  FaPython,
  FaReact,
  FaRegHospital,
  FaShippingFast,
  FaSwift,
  FaVuejs,
  IoLogoAndroid,
  IoLogoIonic,
  IoLogoJavascript,
  MdCastForEducation,
  PiWatchFill,
  RiAngularjsLine,
  RiTailwindCssFill,
  SiCakephp,
  SiDjango,
  SiFlutter,
  SiTypescript,
  SiXamarin,
  TbBrandReactNative,
} from '../Index';

const withSuspense = (IconComponent: any, className = '') => (
  <Suspense fallback={<div>loading...</div>}>
    <IconComponent className={className} />
  </Suspense>
);

export const frontendtechnologies = [
  { icon: withSuspense(RiAngularjsLine), name: 'AngularJS' },
  { icon: withSuspense(FaReact), name: 'ReactJS' },
  { icon: withSuspense(FaVuejs), name: 'VueJS' },
  { icon: withSuspense(IoLogoJavascript), name: 'JavaScript' },
  { icon: withSuspense(SiTypescript), name: 'TypeScript' },
  { icon: withSuspense(DiHtml5), name: 'HTML5' },
  { icon: withSuspense(FaCss3Alt), name: 'CSS3' },
  { icon: withSuspense(RiTailwindCssFill), name: 'Tailwind CSS' },
  { icon: withSuspense(SiFlutter), name: 'Flutter' },
];

export const mobiletechnologies = [
  { icon: withSuspense(FaApple), name: 'iOS' },
  { icon: withSuspense(IoLogoAndroid), name: 'Android' },
  { icon: withSuspense(FaSwift), name: 'Swift' },
  { icon: withSuspense(SiFlutter), name: 'Flutter' },
  { icon: withSuspense(IoLogoIonic), name: 'Ionic' },
  { icon: withSuspense(TbBrandReactNative), name: 'React Native' },
  { icon: withSuspense(SiXamarin), name: 'Xamarin' },
];

export const backendtechnologies = [
  { icon: withSuspense(AiOutlineDotNet), name: '.net' },
  { icon: withSuspense(FaPhp), name: 'PHP' },
  { icon: withSuspense(FaJava), name: 'Java' },
  { icon: withSuspense(FaNodeJs), name: 'nodejs' },
  { icon: withSuspense(FaPython), name: 'Python' },
];

export const frameworks = [
  { icon: withSuspense(FaLaravel), name: 'Laravel' },
  { icon: withSuspense(DiCodeigniter), name: 'Codeigniter' },
  { icon: withSuspense(SiDjango), name: 'Django' },
  { icon: withSuspense(SiCakephp), name: 'CakePHP' },
];

export const industries = [
  { icon: withSuspense(BiSolidTShirt, 'text-primary'), name: 'Lifestyle' },
  {
    icon: withSuspense(FaCartShopping, 'text-success'),
    name: 'Retail & E-commerce',
  },
  {
    icon: withSuspense(FaShippingFast, 'text-warning'),
    name: 'Logistics & Transportation',
  },
  {
    icon: withSuspense(FaLaptopHouse, 'text-info'),
    name: 'Smart Technologies',
  },
  { icon: withSuspense(FaRegHospital, 'text-danger'), name: 'Healthcare' },
  {
    icon: withSuspense(MdCastForEducation, 'text-dark'),
    name: 'Education & E-learning',
  },
  { icon: withSuspense(BiSolidBank, 'text-info'), name: 'Banking & Financial' },
  {
    icon: withSuspense(DiHtml5Multimedia, 'text-warning'),
    name: 'Media & Entertainment',
  },
  { icon: withSuspense(FaBuilding, 'text-secondary'), name: 'Real Estate' },
  { icon: withSuspense(PiWatchFill, 'text-danger'), name: 'Wearables' },
  { icon: withSuspense(FaMedkit, 'text-success'), name: 'Hospitality' },
  { icon: withSuspense(FaPlane, 'text-dark'), name: 'Travel & Tourism' },
];
