import dashboardFinal from "../../assets/img/dashboard-final.webp";
export const tabs = [
  {
    title: 'Strategy & Business',
    description:
      'We design business models and growth strategies to be effective and competitive.',
    image:
    dashboardFinal,
  },
  {
    title: 'Product Development',
    description:
      'We design business models and growth strategies to be effective and competitive.',
    image:
    dashboardFinal,
  },
  {
    title: 'Futures Research',
    description:
      'We design business models and growth strategies to be effective and competitive.',
    image:
    dashboardFinal,
  },
];
