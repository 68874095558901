import { useEffect } from 'react';
import { WOW } from 'wowjs';
import { useLocation } from 'react-router-dom';

const useWowEffect = (dependencies: React.DependencyList = []): void => {
  const location = useLocation();

  useEffect(() => {
    const wow = new WOW(
      {
      boxClass:     'wow',      
      animateClass: 'animated', 
      offset:       0,          
      mobile:       false,       
      live:         true       
    }
    )
    wow.init();
    wow.sync();
  }, [location, ...dependencies]);
};

export default useWowEffect;