import React from 'react';
import Slider from 'react-slick';
import './Crousal.css';
import address from '../../assets/img/laundry-address.png';
import fulladdress from '../../assets/img/laundry-full-address.png';
import completeaddress from '../../assets/img/laundry-complete-address.png';
import contact from '../../assets/img/laundry-phone-number.png';

const ProductsImage = [
  { img: address, alt: 'Laundry address' },
  { img: fulladdress, alt: 'Laundry full address' },
  { img: completeaddress, alt: 'Laundry complete address' },
  { img: contact, alt: 'Laundry phone number' },
];

const Crousal: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <button className='slick-next' />,
    prevArrow: <button className='slick-prev' />,
  };

  return (
    <div className='carousel-container'>
      <Slider {...settings}>
        {ProductsImage.map((item, index) => (
          <div key={index}>
            <img
              src={item.img}
              alt={item.alt}
              className='carousel-image'
              loading='lazy'
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Crousal;
