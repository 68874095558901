import React from 'react';
import { serviceData } from '../../utils/MockData/DevelopmentServices';
import './DevelopmentServices.css';
import { UseWowEffect } from '../AllComponents';

const DevelopmentServices = () => {
  UseWowEffect();

  return (
    <div className='container-fluid service py-5'>
      <div className='container py-5'>
        <div
          className='text-center mx-auto mb-5 wow fadeInUp max-Width'
          data-wow-delay='0.1s'
        >
          <h1 className='mb-4 text-primary text-center libre-baskerville-bold'>
            Our Software Development Services
          </h1>
          <p className='mb-0'>
            Mayora Infotech offers a range of services from IoT-based
            Development, WebRTC-based Development to custom Mobile and Web app
            Development tailored to meet clients' needs.
          </p>
        </div>
        <div className='row g-4 justify-content-center'>
          <div className='container mt-5'>
            <div className='row servicedata-boxes '>
              {serviceData.map((service, index) => (
                <div
                  className={`service-item-container col-md-6 col-lg-4 mb-4 d-flex align-items-stretch wow fadeInUp`}
                  data-wow-delay={`${0.2 * index}s`}
                  key={index}
                >
                  <div className='card card-hover-effect  border-0 rounded h-100 h-100 shadow-sm  '>
                    <div className='card-body'>
                      <img
                        src={service.image}
                        alt={service.title}
                        className='card-img-top mx-3 my-3'
                        style={{
                          width: '70px',
                          height: '70px',
                          objectFit: 'contain',
                        }}
                        loading="lazy"
                      />
                      <h4 className='card-title text-start mb-3 fw-bold libre-baskerville-bold'>
                        {service.title}
                      </h4>
                      <p className='card-text text-start nunito'>
                        {service.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentServices;
