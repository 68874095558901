import React from 'react';
import './ProductBlock.css';
import { ProductBlockProps } from '../../types/ProductsBlockTypes';
import { UseWowEffect } from '../AllComponents';

const ProductBlock: React.FC<ProductBlockProps> = ({ products }) => {
  UseWowEffect();
  return (
    <div className='container-fluid blog py-5 mt-4'>
      <div className='container py-5'>
        <section className='product-block container'>
          <div className='row'>
            {products.map((product, index) => (
              <div key={product.id} className=' col-md-6 col-sm-6'>
                <div
                  className={`product-tile wow fadeInUp`}
                  data-wow-delay={`${index * 0.2}s`}
                >
                  <div className='product-details'>
                    <h2 className='libre-baskerville-bold'>{product.title}</h2>
                    <p className='product-description nunito'>
                      {product.description}
                    </p>
                  </div>
                  <div className='image-wrapper'>
                    <img
                      src={product.img}
                      alt={product.title}
                      loading='lazy'
                      className='product-image'
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductBlock;
