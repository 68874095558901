import React from 'react';

const AboutSection = ({ imgSrc, imgAlt, title, children, isReversed }: any) => (
  <section className={`about-section py-5 ${isReversed ? 'bg-light' : ''}`}>
    <div className='container'>
      <div className='row align-items-center'>
        <div
          className={`col-md-6 ${
            isReversed ? 'order-md-2' : ''
          }  `}       
        >
          <img src={imgSrc} alt={imgAlt} className='img-fluid w-75 h-75 animate__zoomIn wow' data-wow-delay='0.1s' loading="lazy" />
        </div>
        <div
          className={`col-md-6 ${
            isReversed ? 'order-md-1' : ''
          } align-items-center text-left wow animate__pulse`}
          data-wow-delay='0.1s'
        >
          {title && <h2 className='text-primary libre-baskerville-bold'>{title}</h2>}
          {children}
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;
