import { lazy } from 'react';

export const Layout = lazy(() => import('./Layout/Layout'));
export const Home = lazy(() => import('./Home/Home'));
export const About = lazy(() => import('./About/About'));
export const Service = lazy(() => import('./Service/Service'));
export const OurFeatures = lazy(() => import('./OurFeatures/OurFeatures'));
export const Blog = lazy(() => import('./Blog/Blog'));
export const BlogSingle = lazy(() => import('./BlogeDetails/BlogeDetails'));
export const TestimonialSection = lazy(
  () => import('./TestimonialSection/TestimonialSection')
);
export const Products = lazy(() => import('./Products/Products'));
export const ProductsDetails = lazy(
  () => import('./ProductsDetails/ProductsDetails')
);
export const Contact = lazy(() => import('./Contact/Contact'));
export const Careers = lazy(() => import('./Career/Career'));
export const JobDetails = lazy(() => import('./JobDetails/JobDetails'));
export const SiteMap = lazy(() => import('./SiteMAp/SiteMap'));
export const NoPage = lazy(() => import('./404_Page/NoPage'));
