import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Footer.css';
import { BackToTop, CopyRight, UseWowEffect } from '../AllComponents';
import { footerLinks } from '../../utils/MockData/Footer';
import { FooterLink, FooterSection } from '../../types/FooterTypes';

const LinkdIn = process.env.REACT_APP_MAYORA_LINKDIN;
const Instagram = process.env.REACT_APP_MAYORA_INSTAGRAM;
const WhatsApp = process.env.REACT_APP_MAYORA_WHATSAPP;

const Footer: React.FC = () => {
  UseWowEffect();
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSitemapClick = () => {
    navigate('/sitemap');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div
        className='container-fluid footer py-5 wow fadeIn'
        data-wow-delay='0.2s'
      >
        <div className='container py-5'>
          <div className='row g-4'>
            {footerLinks.map(
              ({ title, links }: FooterSection, index: number) => (
                <div key={index} className='footer-item custom-col'>
                  <div className='footer-content d-flex flex-column'>
                    <h4 className='mb-4 text-dark libre-baskerville-bold'>
                      {title}
                    </h4>
                    {links.map(({ path, label }: FooterLink) => (
                      <NavLink key={label} to={path} className='nunito'>
                        {label}
                      </NavLink>
                    ))}
                  </div>
                </div>
              )
            )}

            <div className='footer-item custom-col'>
              <div className='footer-content d-flex flex-column'>
                <h4 className='mb-4 text-dark libre-baskerville-bold'>
                  Contact Info
                </h4>
                <NavLink to='#' className='nunito'>
                  <i className='fa fa-map-marker-alt me-2'></i>
                  A-508, Sidhhivinayak Tower, Makarba, Ahmedabad, Gujarat 380051
                </NavLink>
                <NavLink to='#' className='mayora-mail nunito'>
                  <i className='fas fa-envelope me-2'></i>
                  <span className='mayoragmail'>info@mayorainfotech.com</span>
                </NavLink>
                <NavLink to='#' className='nunito'>
                  <i className='fas fa-phone me-2'></i>
                  +917984687892
                </NavLink>
                <div className='d-flex align-items-center'>
                  <i className='fas fa-share fa-lg text-primary me-2'></i>
                  {[
                    { icon: 'whatsapp', link: `${WhatsApp}` },
                    { icon: 'instagram', link: `${Instagram}` },
                    { icon: 'linkedin-in', link: `${LinkdIn}` },
                  ].map(({ icon, link }) => (
                    <NavLink
                      key={icon}
                      className='btn-square btn icon rounded-circle mx-1 border-icon icon-border-remove'
                      to={link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className={`fab fa-${icon}`}></i>
                    </NavLink>
                  ))}
                </div>
                <span
                  onClick={handleSitemapClick}
                  className='sitemap-link libre-baskerville-bold'
                >
                  <i className='bi bi-pin-map-fill'></i>Sitemap
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
      {showBackToTop && <BackToTop />}
    </>
  );
};

export default Footer;
